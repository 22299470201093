import { z } from 'zod';

export const siteSchema = z.enum([
  'zoomApp',
  'app',
  'website',
  'marleeWeb',
  'marleeSEO',
  'chromeApp',
  'slack',
]);
export type Site = z.infer<typeof siteSchema>;
