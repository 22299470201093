import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type InviteDisplay, type InviteSource } from '@f4s/types/analytics';

import { type InviteRequest } from '@/apis/invitations';
import { logError } from '@/lib/log-error';
import { isAxiosError } from '@/lib/utils/error-type-guard';
import * as API from 'APIs';
import { Notification } from 'Components/view/snackbar';

// Pending invites received by the user
export const pendingInvitesQuery = {
  queryKey: ['invitations', 'received', 'pending'],
  queryFn: async () => {
    const response = await API.invitations.getPendingInvitations();
    return response.data;
  },
  staleTime: 1 * 60 * 1000,
  refetchInterval: 1 * 60 * 1000,
};

export const usePendingInvites = () => useQuery(pendingInvitesQuery);

export const useInvitationsInvalidation = () => {
  const client = useQueryClient();
  return () => client.invalidateQueries(['invitations']);
};

export const useFetchPendingOrgInvites = (
  orgId?: number,
  { enabled }: { enabled?: boolean } = {},
) => {
  if (!orgId) {
    throw new Error('Organization ID not provided');
  }

  return useQuery(
    ['invitations.getPendingOrgInvites', orgId],
    () => API.invitations.getPendingOrgInvites(orgId),
    { enabled },
  );
};

export const useAcceptInvite = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (token: string) =>
      API.invitations.acceptInvite(token).then((response) => response.data),
    {
      onSuccess() {
        queryClient.invalidateQueries(pendingInvitesQuery);
        queryClient.invalidateQueries(['infiniteNotifications']);
        queryClient.invalidateQueries(['unreadNotificationsCount']);
        queryClient.invalidateQueries(['connections']);
      },
      onError: (err: any) => {
        let msg;
        if (isAxiosError(err))
          msg = err.response?.data?.message || err.response?.statusText;
        if (!msg) msg = 'Unexpected error accepting invitation';

        Notification.create(msg, 'error');
        logError(err, { tag: 'invitations', method: 'useAcceptInvite' });
      },
    },
  );
};

export const useDeclineInvite = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (token: string) =>
      API.invitations.declineInvite(token).then((response) => response.data),
    {
      onSuccess() {
        queryClient.invalidateQueries(pendingInvitesQuery);
      },
    },
  );
};

export function useGetAllInvitesSentByUser() {
  return useQuery({
    queryKey: ['invitations', 'sent'],
    queryFn: () =>
      API.invitations.getAllInvitesSentByUser().then((response) => response.data),
  });
}

export const useInviteUsersToConnect = () => {
  return useMutation(
    ({
      invitees,
      inviteDisplay,
      inviteSource,
    }: {
      invitees: InviteRequest[];
      inviteDisplay: InviteDisplay;
      inviteSource: InviteSource;
    }) =>
      API.invitations
        .inviteUsersToConnect(invitees, inviteDisplay, inviteSource)
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        if (data) {
          Notification.create('Connection requests sent', 'success');
        }
      },
      onError: () => {
        Notification.create('Error occurred when sending connection requests', 'error');
      },
    },
  );
};
