import { z } from 'zod';

export const talentPoolCreateReqSchema = z.object({
  name: z.string(),
  isPublic: z.boolean().optional(),
});
export const talentPoolUpdateReqSchema = talentPoolCreateReqSchema.partial();

export type TalentPoolCreateReq = z.infer<typeof talentPoolCreateReqSchema>;
export type TalentPoolUpdateReq = z.infer<typeof talentPoolUpdateReqSchema>;

export const talentOpeningCreateReqSchema = z.object({ name: z.string() });
export const talentOpeningUpdateReqSchema = talentPoolCreateReqSchema.partial();

export type TalentOpeningCreateReq = z.infer<typeof talentOpeningCreateReqSchema>;
export type TalentOpeningUpdateReq = z.infer<typeof talentOpeningUpdateReqSchema>;

export const talentProfileCreateReqSchema = z.object({
  name: z.string(),
  isAuthorized: z.boolean().nullish(),
  linkedinUrl: z.string().nullish(),
  countryCode: z.string().nullish(),
  location: z.string().nullish(),
  locationType: z.enum(['remote', 'hybrid', 'office']).nullish(),
  expectedSalary: z.enum(['level1', 'level2', 'level3', 'level4', 'level5']).nullish(),
  expectedSalaryCurrency: z.enum(['USD', 'AUD', 'EUR', 'GBP']).optional(),
  availability: z.enum(['two_weeks', 'four_weeks', 'more']).nullish(),
  companySize: z
    .enum(['small', 'small_medium', 'medium', 'large', 'very_large'])
    .nullish(),
});
export const talentProfileUpdateReqSchema = talentProfileCreateReqSchema.partial();

export type TalentProfileCreateReq = z.infer<typeof talentProfileCreateReqSchema>;
export type TalentProfileUpdateReq = z.infer<typeof talentProfileUpdateReqSchema>;
