import { type GetPendingOrgInvitesResponse, type Invitation } from '@f4s/types';
import { type InviteDisplay, type InviteSource } from '@f4s/types/analytics';

import { httpAgent as http } from './agents/http';

export type InviteRequest = { emailAddress: string } | { userId: number };

const invitePath = '/api/v3/invitations';
const invitePublicPath = '/api/v3/public/invitations';

export const updateInvite = (data: { token: string; status: string }) =>
  http().put(invitePath, {
    inviteToken: data.token,
    status: data.status,
  });

export const getPendingInvitations = async () => {
  return http().get<PendingInvitation[]>(`${invitePath}/`);
};

export const getAllInvitesSentByUser = async () => {
  return http().get<Invitation[]>(`${invitePath}/sent`);
};

export const inviteUsersToConnect = async (
  invitees: InviteRequest[],
  inviteDisplay: InviteDisplay,
  inviteSource: InviteSource,
) => {
  if (invitees.length === 0) return;
  return http().post(`${invitePath}/connect`, { invitees, inviteDisplay, inviteSource });
};

export const getPendingOrgInvites = (orgId: number) =>
  http()
    .get<GetPendingOrgInvitesResponse>(`${invitePath}/pending-org-invites?orgId=${orgId}`)
    .then((res) => res.data);

export const acceptInvite = (token: string) => {
  return http().post(`${invitePublicPath}/${token}/accept`);
};

export const declineInvite = (token: string) =>
  http().post(`${invitePublicPath}/${token}/decline`);

export type PendingInvitation = {
  id: number;
  token: string;
  updatedAt: string;
  sentToEmailAddress: string | null;
  type:
    | 'connection'
    | 'group'
    | 'organization'
    | 'org_and_group'
    | 'request_org'
    | 'organization_admin'
    | 'organization_consultant'
    | 'organization_support';
  groups?: { id: number; name: string; imageUrl: string | null };
  organizations?: { id: number; name: string; logo: string | null };
  fromUser?: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
  };
  toUser?: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
  };
};
